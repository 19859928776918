import Vue from "vue";
    
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const routes = [
    {
        path: '/' ,
        component: () => import('../vues/welcome'),
        meta: { layout: 'welcome' }
    },
    {
        path: '/:booking' ,
        component: () => import('../vues/guide')
    }
]

export default new VueRouter({
    mode: 'history',
    // https://router.vuejs.org/guide/essentials/history-mode.html
    routes: routes
})

