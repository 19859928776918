<template>
    <div>
        <v-container class="py-0 px-0" fluid v-if="need_install">
            <div v-if="isChrome() || isFirefox()">
                <v-card tile>
                    <v-card-title>
                        {{ $t('install.title') }}
                    </v-card-title>
                    <v-card-text>
                        {{ $t('install.explaination') }}
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text
                               @click="installer()"
                               class="red lighten-1 white--text">
                            <v-icon>get_app</v-icon>
                            {{ $t('install.action') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </div>
            <div v-else class="d-md-none">
                <v-card tile>
                    <v-card-title>
                        <v-app-bar flat dense card>
                            <v-toolbar-title class="subheader">
                                {{ $t('install.title') }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="need_install = false">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-app-bar>
                    </v-card-title>
                    <v-card-text>
                        {{ $t('install.explaination') }}
                    </v-card-text>
                    <v-card-actions>
                        <v-dialog
                                style="display:none;"
                                fullscreen hide-overlay transition="dialog-bottom-transition"
                                v-model="dialog_help_install">
                            <template v-slot:activator="{ on }">
                                <v-btn text
                                       v-on="on"
                                       class="red lighten-1 white--text">
                                    <v-icon>help</v-icon>
                                    {{ $t('label.how_to_install') }}
                                </v-btn>
                            </template>
                            <v-card tile>
                                <v-app-bar class="red lighten-1 white--text">
                                    <v-btn icon dark @click="dialog_help_install = false">
                                        <v-icon>close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>{{ $t('label.how_to_install') }}</v-toolbar-title>
                                </v-app-bar>

                                <v-card-text>
                                    <div class="text--primary pt-5">
                                        <div v-html="installation_guide_ios"></div>
                                    </div>
                                </v-card-text>

                            </v-card>
                        </v-dialog>
                    </v-card-actions>
                </v-card>
            </div>
        </v-container>
        <v-container class="pa-0 mb-0 mt-4">
            <div class="pa-2" v-if="need_install && typeof booking !== 'undefined' && !isAfterCheckoutTime()">
                <v-btn text
                       block
                       :href="pdf"
                       target="_blank"
                       class="red lighten-1 white--text">
                    <v-icon>cloud_download</v-icon>&nbsp;
                    {{ $t('label.or_download_pdf') }}
                </v-btn>
            </div>
        </v-container>
    </div>
</template>

<script>
    import browser from '../mixins/browserDetectorMixin'
    import downloadPdf from '../mixins/downloadPdfMixin'
    import browserUtils from '../mixins/browserUtilsMixin'
    import axios from 'axios'

    export default {
        name: "Install",
        mixins: [browser, downloadPdf, browserUtils],
        data: () => ({
            need_install: false,
            installer: undefined,

            dialog_help_install: false
        }),
        computed: {
            installation_guide_ios() {
                return this.$t('label.installation_guide_ios');
            }
        },
        created() {

            if(this.isChrome() || this.isFirefox()) {

                let installPrompt

                window.addEventListener("beforeinstallprompt", e => {
                    e.preventDefault()

                    installPrompt = e

                    this.need_install = true
                })

                this.installer = () => {
                    this.need_install = false

                    installPrompt.prompt();

                    installPrompt.userChoice.then(result => {
                        if(result.outcome === 'accepted') {
                            console.log('accepted')
                        }
                        else {
                            console.log('denied')
                        }
                        installPrompt = null
                    })
                }
            }
            // other browser
            else {
                this.need_install = !this.isPwaInstalled()
            }
        }
    }
</script>

<style scoped>

</style>