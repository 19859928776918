<template>
	<div class="d-flex flex-column justify-center align-center fill-height">
		<div class="text-center align-self-center contain">
			<h1>Coucouguide</h1>
			<h2>by <a href="https://coucounco.ch">coucounco.ch</a></h2>
			<p>An online guide for vacation homes.</p>
			<p>
			<span class="icons">
				<span class="h4 text--white">Stay tuned</span>
				<br>
				<a href="mailto:hello@coucounco.ch" target="_blank" class="gdlr-core-social-network-icon" title="Email">
				<i class="fa fa-envelope-o"></i></a>
				<a href="https://facebook.com/coucounco/" target="_blank" class="gdlr-core-social-network-icon" title="Facebook">
				<i class="fa fa-facebook"></i></a>
				<a href="https://linkedin.com/company/coucou&amp;co/" target="_blank" class="gdlr-core-social-network-icon" title="Linked-in">
				<i class="fa fa-linkedin"></i></a>
				<a href="https://twitter.com/coucounco" target="_blank" class="gdlr-core-social-network-icon" title="Twitter">
				<i class="fa fa-twitter"></i></a>
				<a href="https://www.instagram.com/coucounco/" target="_blank" class="gdlr-core-social-network-icon" title="Instagram">
				<i class="fa fa-instagram"></i></a>
			</span>
			</p>
			<link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous">
		</div>
	</div>
</template>

<script>
    export default {
        name: "Welcome"
    }
</script>

<style>
    html {
    }
	html, body {
		box-sizing: border-box;
		height: 100%;
	}
    body {
        background: #ea5b63 !important;
        color: white !important;
        font-family: "Arial", Helvetica, sans-serif;
        height: 100%;
        width: 100%;
    }
	.d-flex {
		display: flex;
	}
	.flex-column {
		flex-direction: column;
	}
	.justify-center {
		justify-content: center;
	}
	.align-center {
		align-items: center;
	}
	.contain {
		padding-top: 10px;
		padding-bottom : 50px;
	}
    h1 {
        font-size: 4em;
        margin: 0px;
    }
    h2 {
        font-size: 2.5em;
        margin:0px;
    }
	.h4 {
		font-size: 1.3em;
		font-weight: bold;
	}
    p {
        padding-top: 20px;
        padding-bottom: 0px;
    }
    a {
        color: white;
        text-decoration: none;
    }
    a:hover {
        color: #383838;
    }
    .icons {
        font-size: 1.5em;
        margin-top:0px;
        background: #383838;
        border-radius: 7px 7px 7px 7px;
        -moz-border-radius: 7px 7px 7px 7px;
        -webkit-border-radius: 7px 7px 7px 7px;
        padding: 15px 15px 10px 15px;
        display: inline-block;
        box-shadow: 0px 0px 50px rgba(0, 0, 0,0.08);
        -moz-box-shadow: 0px 0px 50px rgba(0, 0, 0,0.08);
        -webkit-box-shadow: 0px 0px 50px
        rgba(0, 0, 0,0.08);
    }
    .icons a i{
        padding: 10px 10px;
    }
    .fa-linkedin{
        color:#0077B5!important;
    }
    .fa-facebook {
        color:#4267B2!important;
    }
    .fa-tripadvisor {
        color:#00AF87!important;
    }
    .fa-twitter {
        color:#059FF5!important;
    }
    .fa-instagram {
        background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .footer-icons .fa-envelope-o{
        color:white!important;
    }
    i.fa:hover, .fa-linkedin:hover, .fa-facebook:hover, .fa-tripadvisor:hover, .fa-twitter:hover, .footer-icons .fa-envelope-o:hover{
        color: #EA5B63!important;
    }
    .fa-instagram:hover {
        background:transparent!important;
        color:#EA5B63!important;
        -webkit-text-fill-color: #EA5B63;
        backdground: radial-gradient(#EA5B63, #EA5B63)!important;
    }

	.text-center {
		text-align: center;
	}
</style>