import axios from 'axios'
import * as types from '../mutation-types'

export default {
  state: {
    booking: undefined,
    booking_error: undefined
  },

  getters: {
    booking: state => state.booking,
    booking_error: state => state.booking_error
  },

  mutations: {
    [types.FETCH_BOOKING_SUCCESS] (state, { booking }) {
      state.booking = booking
      state.booking_error = undefined
    },

    [types.FETCH_BOOKING_FAILURE] (state, { error }) {
      state.booking = undefined
      state.booking_error = error
    }
  },

  actions: {
    fetchBooking ({commit}, payload) {
      // TODO: put that in a .env
      if(typeof process.env.API_URL === 'undefined'){
        console.log('ERROR: API_URL not set');
        let error = {
          message: 'ERROR: API_URL not set'
        }
        commit(types.FETCH_BOOKING_FAILURE, { error: error })
        return;
      }
      axios.get(process.env.API_URL + payload.id)
          .then(function(response) {
            commit(types.FETCH_BOOKING_SUCCESS, { booking: response.data.data })
          })
          .catch(function(error) {
            console.error(error.response)
            commit(types.FETCH_BOOKING_FAILURE, { error: error.response })
          })
    }
  }
};