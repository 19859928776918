var mixin = {
    methods: {
        t(contents) {
            let content = contents.filter((content) => content.language === this.$i18n.locale)
            if(typeof content[0] !== 'undefined') {
                return content[0]
            }
            let contentFallback = contents.filter((content) => content.language === this.$i18n.fallbackLocale)
            if(typeof contentFallback[0] !== 'undefined') {
                return contentFallback[0]
            }
            return ''
        }
    }
};

export default mixin;