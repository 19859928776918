<template>
    <div>
        <v-list flat>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="title">
                        {{ $t('title') }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item-group v-if="typeof booking !== 'undefined'">
                <v-dialog
                        style="display:none;"
                        fullscreen hide-overlay transition="dialog-bottom-transition"
                        v-model="dialog_emergency">
                    <template v-slot:activator="{ on }">
                        <v-list-item v-on="on">
                            <v-list-item-icon>
                                <v-icon>local_hospital</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ $t('label.emergency') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-card tile>
                        <v-app-bar class="red lighten-1 white--text">
                            <v-btn icon dark @click="dialog_emergency = false">
                                <v-icon>close</v-icon>
                            </v-btn>
                            <v-toolbar-title>{{ $t('label.emergency') }}</v-toolbar-title>
                        </v-app-bar>

                        <v-list two-line>
                            <v-list-item v-for="item in emergencies_number">
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.number }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action v-if="item.quick_call">
                                    <v-btn icon
                                           :href="'tel:' + item.number">
                                        <v-icon color="grey lighten-1">phone</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>


                        <v-divider></v-divider>

                        <v-card-text class="pt-5">
                            {{ t(booking.rental.contents).more_emergencies }}
                        </v-card-text>

                    </v-card>
                </v-dialog>
                <v-dialog
                        style="display:none;"
                        fullscreen hide-overlay transition="dialog-bottom-transition"
                        v-model="dialog_contact">
                    <template v-slot:activator="{ on }">
                        <v-list-item v-on="on">
                            <v-list-item-icon>
                                <v-icon>local_phone</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ $t('label.contact_us') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-card tile>
                        <v-app-bar class="red lighten-1 white--text">
                            <v-btn icon dark @click="dialog_contact = false">
                                <v-icon>close</v-icon>
                            </v-btn>
                            <v-toolbar-title>{{ $t('label.contact_us') }}</v-toolbar-title>
                        </v-app-bar>

                        <v-card-text class="pt-4 text--primary">
                            <v-row>
                                <v-col cols="5">
                                    <strong>{{ $t('label.agency') }}</strong>
                                </v-col>
                                <v-col cols="7">
                                    <div v-html="t(booking.rental.contents).contact_agency"></div>
                                </v-col>


                                <v-col cols="5">
                                    <strong>{{ $t('label.email') }}</strong>
                                </v-col>
                                <v-col cols="7">
                                    <a :href="'mailto:' + t(booking.rental.contents).contact_email">
                                        {{ t(booking.rental.contents).contact_email }}
                                    </a>
                                </v-col>


                                <v-col cols="5">
                                    <strong>{{ $t('label.phone') }}</strong>
                                </v-col>
                                <v-col cols="7">
                                    <a :href="'tel:' + t(booking.rental.contents).contact_phone">
                                        {{ t(booking.rental.contents).contact_phone }}
                                    </a>
                                </v-col>


                                <v-col cols="5">
                                    <strong>{{ $t('label.website') }}</strong>
                                </v-col>
                                <v-col cols="7">
                                    <a :href="t(booking.rental.contents).contact_url" target="_blank">{{ t(booking.rental.contents).contact_url }}</a>
                                </v-col>


                                <v-col cols="5">
                                    <strong>{{ $t('label.booking_website') }}</strong>
                                </v-col>
                                <v-col cols="7">
                                    <a :href="t(booking.rental.contents).booking_url" target="_blank">{{ t(booking.rental.contents).booking_url }}</a>
                                </v-col>


                                <v-col cols="5">
                                    <strong>{{ $t('label.office_hours') }}</strong>
                                </v-col>
                                <v-col cols="7">
                                    <div v-html="t(booking.rental.contents).contact_hour"></div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-list-item @click="downloadPdf" v-if="!isAfterCheckoutTime()">
                    <v-list-item-icon>
                        <v-icon>cloud_download</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('label.download_pdf') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <LocaleChanger></LocaleChanger>
            </v-list-item-group>

        </v-list>
    </div>
</template>

<script>
    import LocaleChanger from "./LocaleChanger"
    import translationMixin from '../mixins/translationMixin'
    import downloadPdf from '../mixins/downloadPdfMixin'

    export default {
        mixins: [translationMixin, downloadPdf],
        components: {LocaleChanger},
        name: "Drawer",
        data: () => ({
            dialog_emergency: false,
            dialog_contact: false
        }),
        computed: {
            booking() {
                return this.$store.state.booking.booking
            },
            emergencies_number() {
                let self = this
                return [
                    {
                        title: 'label.emergencies',
                        number: self.t(self.booking.rental.contents).emergencies,
                        quick_call: true
                    },
                    {
                        title: 'label.police_department',
                        number: self.t(self.booking.rental.contents).police_department,
                        quick_call: true
                    },
                    {
                        title: 'label.fire_department',
                        number: self.t(self.booking.rental.contents).fire_department,
                        quick_call: true
                    },
                    {
                        title: 'label.ambulance',
                        number: self.t(self.booking.rental.contents).ambulance,
                        quick_call: true
                    },
                    {
                        title: 'label.toxilogical_center',
                        number: self.t(self.booking.rental.contents).toxilogical_center,
                        quick_call: false
                    }
                ]
            }
        },
        methods: {
            bookingReady: function() {
                // booking is ready
            },

            downloadPdf() {
                if(document.body.classList.contains('offline')) {
                    this.$emit('throw-offline')
                    return
                }
                window.open(this.pdf)
            }
        },
        watch: {
            'booking': function(booking) {
                if(typeof booking !== 'undefined') {
                    if(!this.$cookies.isKey('language')) {
                        this.$i18n.locale = this.booking.language
                    }
                    this.bookingReady()
                }
            }
        }
    }
</script>

<style scoped>

</style>