var mixin = {
    data: () => ({
        browserdetect_isOpera: false,
        browserdetect_isFirefox: false,
        browserdetect_isSafari: false,
        browserdetect_isIE: false,
        browserdetect_isEdge: false,
        browserdetect_isChrome: false,
    }),
    methods: {
        init() {
            // Opera
            this.browserdetect_isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0
            // Firefox 1.0+
            this.browserdetect_isFirefox = typeof InstallTrigger !== 'undefined'
            // Safari 3.0+ "[object HTMLElementConstructor]"
            this.browserdetect_isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]" })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))
            // Internet Explorer 6-11
            this.browserdetect_isIE = /*@cc_on!@*/false || !!document.documentMode
            // Edge 20+
            this.browserdetect_isEdge = !this.browserdetect_isIE && !!window.StyleMedia
            // Chrome 1 - 71
            this.browserdetect_isChrome = !!window.chrome

        },

        isChrome() {
            return this.browserdetect_isChrome
        },

        isOpera() {
            return this.browserdetect_isOpera
        },

        isFirefox() {
            return this.browserdetect_isFirefox
        },

        isIE() {
            return this.browserdetect_isIE
        },

        isEdge() {
            return this.browserdetect_isEdge
        },

        isSafari() {
            return this.browserdetect_isSafari
        }
    },
    created() {
        this.init()
    }
};

export default mixin;