<template>
    <component :is="layout">
        <RouterView></RouterView>
    </component>
</template>


<script>
    import Vue from "vue"
    import VueCookies from 'vue-cookies'
    import VueMoment from 'vue-moment'

    import router from './router/router.js'
    import store from './store/index'

    import vuetify from './plugins/vuetify'
    import i18n from './plugins/i18n'

    import AppLayout from './layouts/App'
    import WelcomeLayout from './layouts/Welcome'

    Vue.use(VueMoment)
    Vue.use(VueCookies)

    Vue.config.devtools = true

    // register layouts
    // suffix with -layout
    Vue.component('app-layout', AppLayout)
    Vue.component('welcome-layout', WelcomeLayout)

    // default layout name without the suffix -layout
    const DEFAULT_LAYOUT = 'app'

    export default Vue.extend({
        data: () => ({
            drawer: false
        }),

        computed: {
            layout() {
                return (this.$route.meta.layout || DEFAULT_LAYOUT) + '-layout'
            }
        },

        store: store,
        router: router,
        vuetify: vuetify,
        i18n: i18n,

        mounted() {
            //
            if(!this.$cookies.isKey('language')) {
                this.$cookies.set('language', this.$i18n.locale)
            }
            else {
                this.$i18n.locale = this.$cookies.get('language')
            }
        },

        watch: {
            '$i18n.locale': function(locale) {
                this.$cookies.set('language', locale)
                this.$moment.locale(locale)
            }
        }
    });    
</script>


<style lang="scss" scoped>
    
    .navbar {
        text-align: center;
        
        * + * {
            margin-left: 8px;
        }
    }
</style>