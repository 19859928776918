<template>
    <v-list-group
            prepend-icon="language"
            append-icon="keyboard_arrow_down">
        <template v-slot:activator>
            <v-list-item-title>{{ $t('label.languages') }}</v-list-item-title>
        </template>
        <v-list dense>
            <v-list-item
                    inset
                    v-for="(lang, i) in langs"
                    :key="i"
                    selected
                    @click="action(lang)">
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-title>{{ $t('language.' + lang) }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-list-group>
</template>

<script>
    export default {
        name: "LocalChanger",
        data: () => ({
            langs: ['en', 'fr'/*, 'de', 'it'*/]
        }),
        methods: {
            action(lang) {
                this.$i18n.locale = lang
            }
        }
    }
</script>

<style scoped>

</style>