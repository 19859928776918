import Vue from 'vue';

import App from './app.vue';
import './style.scss';


window.addEventListener("load", () => {
    function handleNetworkChange(event) {
        if (navigator.onLine) {
            document.body.classList.remove("offline");
        } else {
            document.body.classList.add("offline");
        }
    }
    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);
});

new Vue(App).$mount('#vueapp');