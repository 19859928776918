<template>
    <v-app>
        <v-app-bar app class="red lighten-1 white--text">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="white--text" aria-label="Menu">
                <v-icon>menu</v-icon>
            </v-app-bar-nav-icon>
            <v-toolbar-title>{{ $t('title') }} <small class="beta">BETA</small></v-toolbar-title>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" app>
            <Drawer :drawer="drawer" v-on:throw-offline="offline"></Drawer>
            <v-list-item-group>
                <v-list-item @click="reload" dense >
                    <v-list-item-content>
                        <v-btn class="red lighten-1 white--text">
                            <v-icon>refresh</v-icon>
                            {{ $t('label.reload') }}
                        </v-btn>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>

        </v-navigation-drawer>
        <v-content>
            <Install></Install>
            <slot />
        </v-content>
        <div v-if="isDebug">
            <p>Crhome : {{ isChrome() }}</p>
            <p>Opera : {{ isOpera() }}</p>
            <p>Firefox : {{ isFirefox() }}</p>
            <p>IE : {{ isIE() }}</p>
            <p>Edge : {{ isEdge() }}</p>
            <p>Safari : {{ isSafari() }}</p>
        </div>
        <v-snackbar
                v-model="snackbar"
        >
            {{ $t('label.offline_message') }}
            <v-btn
                    color="red lighten-1"
                    text
                    @click="snackbar = false"
            >
                {{ $t('label.close') }}
            </v-btn>
        </v-snackbar>
    </v-app>
</template>

<script>
    import Install from '../components/Install'
    import Drawer from '../components/Drawer'
    import browser from '../mixins/browserDetectorMixin'

    export default {
        name: "App",
        components: {Drawer, Install},
        mixins: [browser],
        data: () => ({
            drawer: false,
            snackbar: false
        }),

        computed: {
            isDebug() {
                return process.env.DEBUG === 'true'
            }
        },

        methods: {
            reload() {

                if(document.body.classList.contains('offline')) {
                    this.offline()
                    return
                }

                if ('serviceWorker' in navigator && navigator.serviceWorker.controller != null) {
                    // Send the service worker a message to clear the cache.
                    const messageChannel = new MessageChannel();
                    navigator.serviceWorker.controller.postMessage({
                        action: 'clearcache',
                        cacheName: 'coucou_guide_cache'
                    }, [messageChannel.port2])
                }
                //if(this.isSafari()) {
                window.location.replace(
                    window.location.origin + window.location.pathname + '?' + Math.random().toString(36).substring(7)
                );
                //}
                //else {
                //    window.location.reload()
                //}

            },

            offline() {
                this.snackbar = true
            }
        },
    }
</script>

<style scoped>
    .beta {
        font-size: 0.5em;
    }
</style>