var mixin = {
    computed: {
        booking() {
            return this.$store.state.booking.booking
        },

        pdf() {
            return process.env.API_URL + this.booking.ref + '/pdf/' + this.$i18n.locale
        }
    },
    methods: {
        isAfterCheckoutTime() {
            let current_time = this.$moment()
            let date_to = this.$moment(this.booking.to)
            let checkout_time = this.$moment(process.env.BOOKING_DEFAULT_CHECKOUT_TIME, 'HH:mm')

            date_to.set({
                hour: checkout_time.get('hour'),
                minute: checkout_time.get('minute'),
                second: checkout_time.get('second')
            });

            return current_time.isAfter(date_to)
        },
    }
};

export default mixin;